import axios from "axios";

const doLogout = () => {
  const token = localStorage.getItem("token");

  try {
    axios
      .post(`${process.env.REACT_APP_API_PATH}/logout`, { token })
      .then((res) => {})
      .catch((err) => console.log(err))
      .finally(() => {
        localStorage.removeItem("token");
        localStorage.clear();
        window.location.href = "/";
      });
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/";
  }
};

export default doLogout;
