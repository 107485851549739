import * as React from "react";
import Modal from "@mui/material/Modal";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Backdrop from "@mui/material/Backdrop";
// @mui material components
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import FavoritesForm from "./FavoritesForm";
import { Card } from "@mui/material";

export default function QuestionModal({ isvisibale, hanldeComplete }) {
  useEffect(() => {
    Aos.init({
      duration: 200,
      offset: 100,
    });
    Aos.refresh();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isvisibale}
        sx={{ alignItems: "center" }}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <BasicLayout>
          {/* <Card sx={{ width: "100%", maxWidth: 700, p: 2 }}> */}
          <FavoritesForm hanldeComplete={hanldeComplete} />
          {/* </Card> */}
        </BasicLayout>
      </Modal>
    </div>
  );
}
