import React, { useEffect, useRef, useState } from "react";
import BannerImage from "../assets/images/FrontPage/banner-img.svg";
import Header from "./Header";
import UsersGroupIcon from "../assets/images/FrontPage/users-group.svg";
import FriendshipIcon from "../assets/images/FrontPage/carbon_friendship.svg";
import HandshakeIcon from "../assets/images/FrontPage/handshake.svg";
import "MainComponet/css/custom.css";
import FqaSection from "./FqaSection";
import Footer from "./Footer";
import whyConnectar from "../assets/images/FrontPage/why_the-connector.svg";
import Organisation from "../assets/images/FrontPage/what-we-do.svg";
import referencability from "../assets/images/FrontPage/referencability.svg";
import Methodology from "../assets/images/FrontPage/methodology.svg";
import empathyIcon from "../assets/images/FrontPage/empathy.svg";
import communicationIcon from "../assets/images/FrontPage/communication.svg";
import socialCognitionIcon from "../assets/images/FrontPage/social-cognition.svg";
import ideaIcon from "../assets/images/FrontPage/idea.svg";
import altruismIcon from "../assets/images/FrontPage/hand-holding-user.png";
import reflectionIcon from "../assets/images/FrontPage/brain.svg";
import Avatar from "../assets/images/FrontPage/avatar-group.png";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import connectVideo from "../assets/images/Video/Connectar08.e09952939f4fa6f9965f.mp4";
import howItWork from "../assets/images/Video/TheConnectarFinal.mp4"
import Videoplayer from "./Videoplayer";
const MainSection = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const videoRef = useRef(null);
  const videoPlayRef = useRef(null);
  const [showHowWorkModal, setShowHowWorkModal] = useState(false);
  const isFirstVisit = localStorage.getItem("isFirstTimeVisit");

  useEffect(() => {
    if (!isFirstVisit) {
      localStorage.setItem("isFirstTimeVisit", true);
      setShowVideoModal(true);
    }

    const handleEscKey = (event) => {
      if (event.keyCode === 27) closeVideoModal();
    };
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isFirstVisit]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     AOS.init({ duration: 500, delay: 50 });
  //     AOS.refreshHard();
  //   }, 100);
  // }, []);

  const openVideoModal = () => {
    setShowVideoModal(true);
    setVideoPlayed(true);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };
  const closeVideoModal = () => {
    setShowVideoModal(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  };
 
  const openWorkModal = () => {
    setShowHowWorkModal(true);
    if (videoPlayRef.current) {
      videoPlayRef.current.currentTime = 0;
      videoPlayRef.current.play(); 
    }
  };

  const closeWorkModal = () => {
    setShowHowWorkModal(false);
    if (videoPlayRef.current) {
      videoPlayRef.current.pause(); 
      videoPlayRef.current.currentTime = 0; 
    }
   
  };
 useEffect(()=>{
setTimeout(()=>{
  window.scroll(0,0);
  AOS.init({duration:500, delay:50});
  AOS.refreshHard();
  setTimeout(()=>{
    document.getElementById("footer");
    AOS.refreshHard();
  },1000)
},100)
 },[])
  return (
    <>
      <Header />
      <figure>
        <section className="container">
          <section className="row align-items-center" data-aos="fade-right">
            <section className="col-sm-6 bannerContent">
              <h1>
                The <span>Connectar </span>
              </h1>
              <h2>Corporate Connection Platform</h2>
              <p className="font justify-text">
                Helping your organisation to improve job satisfaction, productivity and wellness,
                through connecting your employees regardless of geographic or departmental
                separation.
              </p>

              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <button
                  className="btn btn-primary d-inline-flex align-items-center main-btn"
                  onClick={openVideoModal}
                >
                  Why Connectar{" "}
                  <i class="fa fa-play-circle-o" aria-hidden="true" style={{ color: "white", fontSize:"25px" }}></i>{" "}
                </button>
                <button
                  className="btn btn-primary d-inline-flex align-items-center main-btn"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white",fontSize:"25px" }}
                  ></i>
                </button>
              </div>
            </section>
            <section class="col-sm-6">
              <img src={BannerImage} alt="" />
            </section>
          </section>
        </section>
        {/* Video Modal */}

        {showVideoModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeVideoModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                    <video
                      ref={videoRef}
                      id="videoPlayer"
                      title="Video Title"
                      className="videoframe"
                      loop
                      autoPlay
                      controls
                      allow="autoplay; fullscreen;"
                      allowFullScreen
                      preload="none"
                      muted={!videoPlayed}
                      onCanPlay={() => videoRef.current?.play()}
                    >
                      <source src={connectVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
          {showHowWorkModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeWorkModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                   
                    <Videoplayer ref={videoPlayRef}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </figure>

      {/* three cols */}
      <section class="section-widget primary-col three-cols">
        <section class="container">
          <section class="row text-center">
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={UsersGroupIcon} alt="" />
              </i>
              <h4 class="mb-2">Collaboration</h4>
              <p>
                When employees feel connected to one another, regardless of where they are located
                or which department they belong to, they are more likely to collaborate effectively.
                This collaboration can lead to the sharing of ideas, pooling of resources, and
                ultimately, better problem-solving.
              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={FriendshipIcon} alt="" />
              </i>
              <h4 class="mb-2">Knowledge sharing</h4>
              <p>
                In a connected workplace, information flows more freely. Employees are more inclined
                to share their expertise, experiences, and insights with others, leading to a more
                knowledgeable and skilled workforce. This can be particularly beneficial in
                environments where different departments have complementary skills or knowledge.
              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={HandshakeIcon} alt="" />
              </i>
              <h4 class="mb-2">Employee engagement and retention</h4>
              <p>
                Feeling connected to one's colleagues and the organization as a whole fosters a
                sense of loyalty and commitment. Employees who feel supported and valued are more
                likely to be engaged in their work and less likely to seek opportunities elsewhere.
              </p>
            </section>
          </section>
        </section>
      </section>
      {/* baner text section */}

      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={whyConnectar} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                Why <span>The Connectar</span>
              </h2>
              <p className="font justify-text">
                Most of us have heard someone say, “I would have left that job long ago if it
                weren’t for the people I worked with”. Imagine you could replicate this, but on a
                global scale, where you aren’t limited by geographic or departmental separation; and
                instead of limiting the staff within your organisation to dealing only with other
                employees they typically engage with, you open up the opportunity for them to
                connect with anyone across the organisation.
              </p>
              <NavLink to="/Why-The-Connectar" className="btn btn-primary">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center  order-md-2">
              <img src={referencability} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                What does the <span>data tell us?</span>
              </h2>
              <p className="font justify-text">
                Workplace connections are vital for fostering a positive organizational culture,
                increasing productivity, and promoting employee satisfaction. So, what does the data
                tell us?
              </p>
              <NavLink to="/Why-you-should" className="btn btn-primary">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={Methodology} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                <span>The Connectar</span> Methodology
              </h2>
              <p className="font justify-text">
                The Connectar, is focused on bringing all of the staff within your organisation
                together, so they can finally move as one towards a common goal. To help foster a
                culture where shared ideas, and an eagerness to go above and beyond become the norm
                assisting your company to excel, and achieve the status of being a beacon for great
                places to work. And how do we do this?
              </p>
              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/Methodology" className="btn btn-primary">
                  Read More
                </NavLink>
                <button
                  className="btn btn-primary d-inline-flex align-items-center"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white", fontSize:"22px" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center order-2">
              <img src={Organisation} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                Why you should <span>do something now</span>
              </h2>
              <p className="font justify-text">
                For many years there has been an undercurrent of awareness surrounding the
                importance of connection, ironically since the adoption of smart technology and our
                ability to be anywhere in the world much more easily than ever before; we’ve never
                had more issues with true connectedness, whereby we feel included and accepted.
              </p>
              <NavLink to="/WhyNow" className="btn btn-primary">
                Read More
              </NavLink>
            </div>
          </div>
        </section>
      </section>
      {/* 2 row section 3 by 3 */}
      <section className="section-widget primary-col color-white Benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={empathyIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Empathy</h3>
                  <p className="font justify-text">
                    The Connectar's 'Pollen Pairing' algorithm fosters empathy by understanding each
                    employee's unique attributes. This personalized approach cultivates empathy as
                    employees engage with others who resonate with their values. By connecting with
                    colleagues they might not have otherwise met, employees gain a deeper
                    understanding of diverse perspectives, enriching workplace relationships.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Empathy--> */}

            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={reflectionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Employee Well-being</h3>
                  <p className="font justify-text">
                    A report by the Society for Human Resource Management (SHRM) revealed that
                    employees with strong social connections at work are generally healthier and
                    experience less stress (SHRM, 2020).
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Capacity for Reflection and Self-awareness--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={socialCognitionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Social Cognition</h3>
                  <p className="font justify-text">
                    The Connectar revolutionizes social cognition by delving beyond surface-level
                    traits to facilitate genuine connections. Through its comprehensive
                    questionnaire and proprietary algorithm, it analyzes factors like interests and
                    values, driving collaboration and innovation. By facilitating meaningful
                    interactions, The Connectar strengthens social cognition within the
                    organization, enabling employees to navigate complex social dynamics
                    effectively.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Social Cognition--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={ideaIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Innovation</h3>
                  <p className="font justify-text">
                    When people from diverse backgrounds and areas of expertise come together, it
                    creates fertile ground for innovation. Connection breaks down silos and
                    encourages cross-pollination of ideas, which can spark creativity and lead to
                    innovative solutions to problems.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Cultural and Emotional Intelligence--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={altruismIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Team cohesion</h3>
                  <p className="font justify-text">
                    Connection fosters a sense of belonging and unity among team members. Even if
                    they are physically distant or work in different departments, feeling connected
                    helps individuals understand that they are part of a larger team working towards
                    common goals. This cohesion improves morale and overall productivity.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Altruism and Cooperation--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={communicationIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Conflict resolution</h3>
                  <p className="font justify-text">
                    Strong connections between employees can also facilitate the resolution of
                    conflicts. When individuals have established relationships built on trust and
                    mutual respect, they are more likely to address conflicts directly and
                    constructively, rather than letting them fester and escalate.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Communication Skills--> */}
          </div>
        </div>
      </section>

      {/* <FqaSection /> */}

      <section className="section-widget">
        <div className="container">
          <div className="getInTouch text-center" data-aos="fade-right">
            <i className="d-block mb-3">
              <img src={Avatar} alt="" />
            </i>
            <h5 className="interFont">Still have questions?</h5>
            <p className="font ">
              If you have any questions, or you feel this may benefit your organisation; please
              reach out to us here.
            </p>
            <NavLink to="/contact">
              <button class="btn btn-primary">Get in touch</button>
            </NavLink>
          </div>
        </div>
      </section>
      <div data-aos="fade-right" id="footer">       <Footer />     </div>
     
    </>
  );
};

export default MainSection;
