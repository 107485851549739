import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Swal from "sweetalert2/dist/sweetalert2.js";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "assets/images/small-logos/image.png";

import { InputAdornment } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function ResetPassword() {
  const { id } = useParams();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    onSubmit: (values) => {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/changepassword/${id}`, values)
        .then((res) => {
          if (res.data.status == 201) {
            Swal.fire("Done!", " password changed successfully", "success");

            navigate("/login");
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    validate: (values) => {
      const errors = {};

      if (!values.password) errors.password = "Password is required";
      else if (
        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(values.password)
      )
        errors.password =
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";

      if (values.password !== values.confirmpassword && values.confirmpassword.length > 0) {
        errors.confirmpassword = "Passwords must match";
      }
      return errors;
    },
  });

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      navigate("/companies");
    }
  });

  const [password, setPassword] = useState("password");
  const [confirmpassword, setConfirmPassword] = useState("password");
  const [showIcon, setShowIcon] = useState(true);
  const [showForgetIcon, setShowForgotIcon] = useState(true);

  const handleShowPassword = () => {
    if (password === "password") {
      setPassword("text");
      setShowIcon(false);
      return;
    }

    setPassword("password");
    setShowIcon(true);
  };

  const handleShowConfirmPassword = () => {
    if (confirmpassword === "password") {
      setConfirmPassword("text");
      setShowForgotIcon(false);
      return;
    }

    setConfirmPassword("password");
    setShowForgotIcon(true);
  };

  return (
    <>
      {" "}
      <form onSubmit={formik.handleSubmit}>
        <BasicLayout>
          <Card>
            <MDBox
              variant="gradient"
              sx={{backgroundColor:"#1e90ff"}}
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDBox
                className="Logo_design"
                borderRadius="lg"
                display="flex"
                alignItems="center"
                zIndex={3}
                component="img"
                src={logo}
                sx={{ height: "80px", margin: "auto " }}
                alt="Brand"
              />
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Reset Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type={password}
                    label="Password"
                    fullWidth
                    name="password"
                    id="outlined-start-adornment"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!showIcon ? (
                            <RemoveRedEyeIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleShowPassword}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {formik.touched.password && formik.errors.password ? (
                      <div>{formik.errors.password}</div>
                    ) : null}
                  </div>
                </MDBox>
                <MDBox mb={2} mt={3}>
                  <MDInput
                    type={confirmpassword}
                    label="ConfirmPassword"
                    fullWidth
                    id="outlined-start-adornment"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {!showForgetIcon ? (
                            <RemoveRedEyeIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleShowConfirmPassword}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleShowConfirmPassword}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    name="confirmpassword"
                    value={formik.values.confirmpassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div style={{ color: "red", fontSize: "14px" }}>
                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                      <div>{formik.errors.confirmpassword}</div>
                    ) : null}
                  </div>
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    // component={Link}
                    // to="/authentication/sign-in/invite"
                  >
                    Confirm
                  </MDButton>
                  <ToastContainer />
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </BasicLayout>
      </form>
    </>
  );
}

export default ResetPassword;
