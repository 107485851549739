import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "../style.css";
import AddIcon from "@mui/icons-material/Add";

export default function FavoritesForm({ showHeading, user = null, hanldeComplete = null }) {
  const [currentInput, setCurrentInput] = useState("");

  const [selectedValues, setSelectedValues] = useState([]);

  const [inputValue, setInputValue] = useState({
    restaurant: "",
    movie: "",
    book: "",
    city: "",
  });

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);

  const handleFormSubmit = () => {
    const errors = formik.errors;
    const values = formik.values;
    const isValid =
      Object.keys(errors).length === 0 &&
      Object.values(values).every((value) => value.length >= 5 && value.length <= 10);

    if (isValid) {
      const formValues = {
        restaurant: values.restaurant,
        movie: values.movie,
        book: values.book,
        city: values.city,
      };

      const token = localStorage.getItem("token");
      axios
        .post(`${process.env.REACT_APP_API_PATH}/saveInterest`, { ...formValues, token })
        .then((res) => {
          Swal.fire("Done!", "Saved!", "success").then(() => {});
          hanldeComplete();
        })
        .catch((err) => console.log(err));
    } else {
      formik.setTouched({
        restaurant: true,
        movie: true,
        book: true,
        city: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      restaurant: "",
      movie: "",
      book: "",
      city: "",
    },
    onSubmit: (values) => {
      handleFormSubmit();
    },
    validate: (values) => {
      let errors = {};

      const fieldLimits = {
        restaurant: {
          min: 5,
          max: 10,
          message: "Please add your favourite restaurants Min 5 and Max 10",
        },
        movie: {
          min: 5,
          max: 10,
          message: "Please add your favourite movies Min 5 and Max 10",
        },
        book: {
          min: 5,
          max: 10,
          message: "Please add your favourite books Min 5 and Max 10",
        },
        city: {
          min: 5,
          max: 10,
          message: "Please add your favourite cities Min 5 and Max 10",
        },
      };

      for (const fieldName in fieldLimits) {
        const fieldValue = values[fieldName];
        const fieldMin = fieldLimits[fieldName].min;
        const fieldMax = fieldLimits[fieldName].max;

        if (!fieldValue) {
          errors[fieldName] = fieldLimits[fieldName].message;
        } else {
          const items = fieldValue.map((item) => item.trim());

          if (items.length < fieldMin || items.length > fieldMax) {
            errors[fieldName] = fieldLimits[fieldName].message;
          } else {
            delete errors[fieldName];
          }
        }
      }

      return errors;
    },
  });

  useEffect(() => {
    if (user) {
      formik.setFieldValue("restaurant", user.favRestaurant);
      formik.setFieldValue("movie", user.favMovie);
      formik.setFieldValue("book", user.favBook);
      formik.setFieldValue("city", user.favCity);
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event, newValue, fieldName) => {
    setInputValue({ ...inputValue, [fieldName]: newValue });
  };

  const handleButtonClick = (fieldName) => {
    const trimmedInput = inputValue[fieldName].trim();

    if (trimmedInput && !formik.values[fieldName].includes(trimmedInput)) {
      formik.setFieldValue(fieldName, [...formik.values[fieldName], trimmedInput]);
      setCurrentInput("");
      setSelectedValues([...selectedValues, inputValue[fieldName].trim()]);
      setInputValue({ ...inputValue, [fieldName]: "" });
    } else {
      const fieldValues = formik.values[fieldName];
      const editedValues = fieldValues.map((value) => {
        if (value === currentInput) {
          return inputValue[fieldName].trim();
        } else {
          return value;
        }
      });

      formik.setFieldValue(fieldName, editedValues);
      setCurrentInput("");
      setInputValue({ ...inputValue, [fieldName]: "" });
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card
        sx={{
          height: "auto",
          marginTop: "30px",
          marginBottom: "10px",
          "@media (min-width:1280px)": {
            width: " 850px",
          },
          "@media (width:768px)": {
            width: " 700px",
          },
          "@media (min-width:1024px)": {
            width: " 850px",
          },
          "@media (width:820px)": {
            width: " 700px",
          },
        }}
      >
        <MDBox
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          variant="gradient"
          sx={{backgroundColor:"#1e90ff"}}
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Tell us about yourself
          </MDTypography>
        </MDBox>

        <MDTypography
          variant="paragraph"
          fontSize="14px"
          style={{ color: "black", margin: "22px" }}
        >
          Please list some of your favourite Books, Restaurants, Movies, and Cities below. We are
          after a minimum of 5, and no more than 10. Simply click the add button below after typing
          each listing to save it.
        </MDTypography>

        <>
          <MDBox pt={1} pb={3} px={3}>
            <MDBox>
              <label style={{ fontSize: "14px" }}>
                Can you name some of your favorite Restaurants?
              </label>
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={formik.values.restaurant || selectedValues}
                onChange={(event, value) => {
                  formik.setFieldValue("restaurant", value);
                }}
                inputValue={inputValue.restaurant}
                onInputChange={(event, newValue) =>
                  handleInputChange(event, newValue, "restaurant")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Restaurants"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={(event) => setCurrentInput(event.target.value)}
                    focused
                  />
                )}
              />
              {formik.touched.restaurant && formik.errors.restaurant && (
                <div className="text-danger" style={{ fontSize: "14px", color: "red" }}>
                  {formik.errors.restaurant}
                </div>
              )}

              <div style={{ display: "flex", alignItems: "center" }}>
                <MDButton
                  display="flex"
                  alignItems="center"
                  color="info"
                  sx={{ backgroundColor: "#0d6efd" }}
                  endIcon={<AddIcon style={{ marginTop: "-4px" }} />}
                  onClick={() => handleButtonClick("restaurant")}
                >
                  Add
                </MDButton>
              </div>
            </MDBox>
            <MDBox>
              <label style={{ fontSize: "14px" }}>Can you name some of your favorite Movies?</label>
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={formik.values.movie}
                onChange={(event, value) => {
                  formik.setFieldValue("movie", value);
                }}
                inputValue={inputValue.movie}
                onInputChange={(event, newValue) => handleInputChange(event, newValue, "movie")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Movies"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={(event) => setCurrentInput(event.target.value)}
                  />
                )}
              />
              {formik.touched.movie && formik.errors.movie && (
                <div className="text-danger" style={{ fontSize: "14px", color: "red" }}>
                  {formik.errors.movie}
                </div>
              )}

              <div style={{ display: "flex", alignItems: "center" }}>
                <MDButton
                  display="flex"
                  alignItems="center"
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  endIcon={<AddIcon style={{ marginTop: "-4px" }} />}
                  onClick={() => handleButtonClick("movie")}
                >
                  Add
                </MDButton>
              </div>
            </MDBox>
            <MDBox>
              <label style={{ fontSize: "14px" }}>Can you name some of your favorite Books?</label>
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={formik.values.book}
                onChange={(event, value) => {
                  formik.setFieldValue("book", value);
                }}
                inputValue={inputValue.book}
                onInputChange={(event, newValue) => handleInputChange(event, newValue, "book")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Books"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={(event) => setCurrentInput(event.target.value)}
                  />
                )}
              />
              {formik.touched.book && formik.errors.book && (
                <div className="text-danger" style={{ fontSize: "14px", color: "red" }}>
                  {formik.errors.book}
                </div>
              )}

              <div style={{ display: "flex", alignItems: "center" }}>
                <MDButton
                  display="flex"
                  alignItems="center"
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  endIcon={<AddIcon style={{ marginTop: "-4px" }} />}
                  onClick={() => handleButtonClick("book")}
                >
                  Add
                </MDButton>
              </div>
            </MDBox>
            <MDBox>
              <label style={{ fontSize: "14px" }}>Can you name some of your favorite Cities?</label>
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={formik.values.city}
                onChange={(event, value) => {
                  formik.setFieldValue("city", value);
                }}
                inputValue={inputValue.city}
                onInputChange={(event, newValue) => handleInputChange(event, newValue, "city")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Cities"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={(event) => setCurrentInput(event.target.value)}
                  />
                )}
              />
              {formik.touched.city && formik.errors.city && (
                <div className="text-danger" style={{ fontSize: "14px", color: "red" }}>
                  {formik.errors.city}
                </div>
              )}

              <div style={{ display: "flex", alignItems: "center" }}>
                <MDButton
                  display="flex"
                  alignItems="center"
                  sx={{ backgroundColor: "#0d6efd" }}
                  color="info"
                  endIcon={<AddIcon style={{ marginTop: "-4px" }} />}
                  onClick={() => handleButtonClick("city")}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  Add
                </MDButton>
              </div>
            </MDBox>

            {!showHeading && (
              <MDButton
                sx={{ backgroundColor: "#0d6efd" }}
                color="info"
                onClick={handleFormSubmit}
                onTouchStart={handleFormSubmit}
                style={{ marginBottom: "20px" }}
              >
                Submit
              </MDButton>
            )}
            <MDBox>
              {showHeading && (
                <>
                  <MDButton variant="gradient" color="info" onClick={handleFormSubmit}>
                    Update
                  </MDButton>
                  <MDButton
                    style={{ marginLeft: "20px" }}
                    sx={{ backgroundColor: "#0d6efd" }}
                    color="info"
                    onClick={hanldeComplete}
                  >
                    Close
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </>
      </Card>
    </form>
  );
}
