// @mui material components
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import "./Style.css";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DataTable from "examples/Tables/DataTable";

import DeleteIcon from "@mui/icons-material/Delete";
import MDInput from "components/MDInput";

function Users() {
  const [UserData, setUserData] = useState([]);
  const [error, setError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const getUsers = () => {
    const token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_PATH}/getusers`, { token })
      .then((res) => {
        if (res.data.status === 200) {
          setUserData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleDeleteuser = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API_PATH}/deleteCompany`, { record })
          .then((res) => {
            if (res.data.status === 200) {
              getUsers();
              Swal.fire("Done!", "Your action was successful", "success");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  const filteredData = UserData.filter((c) => {
    const userName = c.firstname ? c.firstname : "";
    const userLastName = c.lastname ? c.lastname : "";
    const userEmail = c.email ? c.email : "";
    
    const fullName = `${userName} ${userLastName}`.toLowerCase();
    return fullName.includes(searchValue.toLowerCase())  || userEmail.includes(searchValue.toLowerCase());
  });
  useEffect(() => {
    if (filteredData.length === 0 && UserData.length > 0) {
      setError("User not found");
    } else {
      setError("");
    }
  }, [filteredData, UserData]);
  
  

  
  const columns = [
    { Header: "First Name", accessor: "FirstName" },
    { Header: "Last Name", accessor: "LastName" },
    { Header: "Email", accessor: "Email" },
    { Header: "Contact", accessor: "Contact" },
    { Header: "Address", accessor: "City" },
    { Header: "Role", accessor: "Role" },
    { Header: "Actions", accessor: "actions" },
  ];

  const rows = filteredData.map((c) => ({
    FirstName: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {c.firstname ? c.firstname : ""}
      </MDTypography>
    ),
    LastName: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {c.lastname ? c.lastname : ""}
      </MDTypography>
    ),
    Email: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {c.email ? c.email : ""}
      </MDTypography>
    ),
    Contact: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {c.contact ? c.contact : ""}
      </MDTypography>
    ),

    City: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {`${c?.country ? c?.country : ""} ${c?.city ? c?.city : ""} ${c?.Street ? c?.Street : ""} ${
          c?.suburb ? c?.suburb : ""
        }  ${c?.zip ? c?.zip : ""}`}
      </MDTypography>
    ),

    Role: (
      <MDTypography
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
        style={{ fontSize: "14px" }}
      >
        {c.role ? c.role : ""}
      </MDTypography>
    ),
    actions: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ fontSize: "25px" }}>
        <DeleteIcon
          onClick={() => handleDeleteuser(c)}
          className="deleteicon"
          style={{ cursor: "pointer" }}
        />
      </MDTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <Sidenav color="info" brandName="Connectar" routes={routes} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                borderRadius="lg"
                coloredShadow="info"
                sx={{backgroundColor:'#1e90ff'}}
              >
                <MDTypography variant="h6" color="white" style={{ float: "left" }}>
                  User Table
                </MDTypography>

                <MDInput
                  mt={5}
                  label="Search Name"
                  variant="outlined"
                  style={{
                    float: "right",
                    fontSize: "18px",
                    background: "white",
                    borderRadius: "9px",
                    color: "black",
                  }}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </MDBox>
              {error ? (
                <MDBox pt={2} textAlign="center">
                  <MDTypography
                    variant="body1"
                    color="error"
                    sx={{ fontSize: "30px", fontWeight: "bold" }}
                  >
                    {error}
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                    style={{ fontSize: "28px" }}
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Users;