import * as React from "react";
import Modal from "@mui/material/Modal";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Card from "@mui/material/Card";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default function AddCompanyModal(props) {
  const { isVisible, addCompany, Close } = props;
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
    },
    onSubmit: (values) => {
      addCompany(values);
      formik.resetForm();
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) errors.email = "Email is required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
        errors.email = "Invalid email address";
      if (!values.firstname) errors.name = "Name is required";

      return errors;
    },
  });

  const closeModal = () => {
    formik.resetForm();
    Close();
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  return (
    <div>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <BasicLayout>
            <Card>
              <MDBox
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                sx={{backgroundColor:"#1e90ff"}}
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Invite Company
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="name"
                      label="Company Name"
                      fullWidth
                      name="firstname"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched.firstname && formik.errors.firstname ? (
                        <div>{formik.errors.firstname}</div>
                      ) : null}
                    </div>
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      fullWidth
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "17px" }}>
                      {formik.touched.email && formik.errors.email ? (
                        <div color="red">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton sx={{backgroundColor:"#0d6efd"}} color="info" fullWidth type="submit">
                      Submit
                    </MDButton>
                  </MDBox>

                  <MDBox mt={3} mb={1} textAlign="center">
                    <Button sx={{color:"#0d6efd"}} fullWidth onClick={closeModal}>
                      Close
                    </Button>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        </form>
      </Modal>
    </div>
  );
}
