import * as React from "react";
import Modal from "@mui/material/Modal";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material components
import Card from "@mui/material/Card";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export default function ResetUserPassword(props) {
  const { isVisible, addCompany, Close } = props;

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      password: "",
      confirmpassword: "",
    },
    onSubmit: (values) => {
      addCompany(values);
    },
    validate: (values) => {
      const errors = {};

      if (!values.oldpassword) errors.oldpassword = "Password is required";

      if (!values.password) errors.password = "Password is required";
      else if (
        !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/i.test(values.password)
      )
        errors.password =
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character";

      if (values.password !== values.confirmpassword && values.confirmpassword.length > 0) {
        errors.confirmpassword = "Passwords must match";
      }
      return errors;
    },
  });

  const cancelModal = () => {
    formik.resetForm();
    Close();
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  return (
    <div>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <BasicLayout>
            <Card>
              <MDBox
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                sx={{backgroundColor:"#1e90ff"}}
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Reset Password
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox>
                  <MDBox mb={4}>
                    <MDInput
                      label="Old Password"
                      fullWidth
                      type="password"
                      name="oldpassword"
                      id="outlined-start-adornment"
                      value={formik.values.oldpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.touched.oldpassword && formik.errors.oldpassword ? (
                        <div>{formik.errors.oldpassword}</div>
                      ) : null}
                    </div>
                  </MDBox>

                  <MDBox mb={4}>
                    <MDInput
                      label="Password"
                      fullWidth
                      type="password"
                      name="password"
                      id="outlined-start-adornment"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.touched.password && formik.errors.password ? (
                        <div>{formik.errors.password}</div>
                      ) : null}
                    </div>
                  </MDBox>
                  <MDBox mb={2} mt={3}>
                    <MDInput
                      label="Confirm Password"
                      fullWidth
                      type="password"
                      id="outlined-start-adornment"
                      name="confirmpassword"
                      value={formik.values.confirmpassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                        <div>{formik.errors.confirmpassword}</div>
                      ) : null}
                    </div>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton sx={{backgroundColor:"#0d6efd"}} color="info" fullWidth type="submit">
                      Confirm
                    </MDButton>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDButton variant="text" color="info" fullWidth onClick={cancelModal}>
                      Close
                    </MDButton>
                  </MDBox>
                 
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        </form>
      </Modal>
    </div>
  );
}
