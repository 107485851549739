import Icon from "@mui/material/Icon";
import Category from "layouts/Category/Categories";
import { Typography } from "@mui/material";
import AdminProfile from "layouts/Adminprofile/Profile";
import Tables from "layouts/Adminprofile/CompaniesTable";
import Snapshot from "layouts/Adminprofile/Snapshot";
import FeedbackAlluser from "layouts/Adminprofile/FeedbackAlluser";

const routes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Companies</Typography>,
    key: "companies",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/companies",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Categories </Typography>,
    key: "Categories ",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/weighting",
    component: <Category />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Score </Typography>,
    key: "Score ",
    icon: <Icon fontSize="small">Count</Icon>,
    route: "/snapshot",
    // component: <Snapshot />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Feedback </Typography>,
    key: "Feedback ",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/feedbacks",
    component: <FeedbackAlluser />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Profile </Typography>,
    key: "Profile ",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <AdminProfile />,
  },
];

export default routes;
