import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import routes from "routes";
import Sidenav from "examples/Sidenav";
import axios from "axios";
import MDButton from "components/MDButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validateAmounts = (amounts) => {
  const totalAmount = amounts.reduce((sum, { amount }) => sum + parseFloat(amount || 0), 0);
  return totalAmount === 100;
};

const AmountValidationTable = () => {
  const [value, setValues] = useState([]);
  const initialValues = [
    {
      category: "Personality",
      amount: "25",
    },
    {
      category: "Values",
      amount: "25",
    },
    {
      category: "Interest",
      amount: "25",
    },
    {
      category: "Intelligence",
      amount: "25",
    },
  ];
  const [amounts, setAmounts] = useState(initialValues);
  const [isValid, setIsValid] = useState(validateAmounts(amounts));
  const [isSaved, setIsSaved] = useState(true);
  const [recordId, setRecordId] = useState();

  const handleAmountChange = (event, index) => {
    const newAmounts = [...amounts];
    newAmounts[index].amount = event.target.value;
    setAmounts(newAmounts);
    setIsValid(validateAmounts(newAmounts));
    setIsSaved(false);
  };

  const handleSave = () => {
    const log1 = { id: recordId };
    amounts.map((elem) => {
      log1[elem.category] = elem.amount;
    });
    toast.success("Successfully Updated!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    axios
      .post(`${process.env.REACT_APP_API_PATH}/setCategories`, log1)
      .then((res) => {
        if (res.data.status == 200) {
          setRecordId(res.data.data._id);
          setValues(res.data.data);
        }
      })
      .catch((err) => console.log(err));

    setIsSaved(true);
  };

  const getCategories = async () => {
    let rest = "";

    axios
      .post(`${process.env.REACT_APP_API_PATH}/getCategories`)
      .then((res) => {
        if (res.data.status == 200) {
          setRecordId(res.data.data[0]._id);
          setValues(res.data.data);
          rest = res.data.data;
        }
      })
      .catch((err) => console.log(err));
    return rest;
  };

  useEffect(() => {
    if (value.length === 0) {
      getCategories();
    }
    customizeData();
  }, [value]);

  const customizeData = async () => {
    if (value.length > 0) {
      const test = value[value.length - 1];
      let newObj = {};
      const newObj1 = {};
      const temp = [];

      if (test !== null && test !== "undefined") {
        Object.keys(test).forEach((key, index) => {
          if (key === "_id" || key === "__v") return;
          newObj = { category: key, amount: test[key] };
          temp.push(newObj);
        });

        setAmounts(temp);
      }
    }
  };

  return (
    <DashboardLayout>
      <Sidenav color="primary" brandName="Connectar" routes={routes} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                sx={{backgroundColor:"#1e90ff"}}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography
                  variant="h6"
                  color="white"
                  style={{
                    float: "left",
                  }}
                >
                  Weighting Categories
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Table aria-label="Amount Validation Table">
                  <TableHead />
                  <TableBody>
                    {amounts.map((row, index) => (
                      <TableRow key={row.category}>
                        <TableCell component="th" scope="row">
                          {row.category}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            value={row.amount}
                            onChange={(event) => handleAmountChange(event, index)}
                            InputProps={{
                              endAdornment: <Typography>%</Typography>,
                              inputProps: { min: 0, max: 100 },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell />
                      <TableCell align="right">
                        {isValid ? (
                          <span style={{ color: "green" }}>{/* <h3>100%</h3> */}</span>
                        ) : (
                          <span style={{ color: "red" }}>
                            <h3>Invalid amount total must be 100%</h3>
                          </span>
                        )}
                        <MDBox
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",

                            marginRight: "50px",
                            marginBottom: "10px",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <MDBox mx={2} mt={-3} py={3} px={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} xl={12}>
                      <MDBox mb={2}>
                        <MDButton
                          style={{
                            color: "white",
                            border: "1px solid white",
                            marginBottom: "4%",
                            background: " #0d6efd",
                          }}
                          fullWidth
                          disabled={!isValid || isSaved}
                          onClick={handleSave}
                        >
                          save
                        </MDButton>
                        <ToastContainer />
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

export default AmountValidationTable;
