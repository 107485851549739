import { useState } from "react";
import { Navigate, Outlet } from "react-router";

const PrivateUserRoutes = () => {
  const [user, setUser] = useState(localStorage.getItem("role") === "user" ? true : null);

  return user ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateUserRoutes;
