import { useState } from "react";
import { Navigate, Outlet } from "react-router";

const PrivateCompanyRoutes = () => {
  const [company, setCompany] = useState(localStorage.getItem("role") === "company" ? true : null);

  return company ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateCompanyRoutes;
