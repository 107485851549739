import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
import { useFormik } from 'formik';
import axios from "axios";

const ContactUs = () => {
  useEffect(() => {
    setTimeout(()=>{
      window.scroll(0,0);
      AOS.init({duration:500, delay:50});
      AOS.refreshHard();
      setTimeout(()=>{
        document.getElementById("footer");
        AOS.refreshHard();
      },1000)
    },100)
  }, []);

  const formik = useFormik({
    initialValues: {
      contactName: '',
      Organisation: '',
      corporateEmail: '',
      contactNumber: '',
      Comments: '',
    },
    onSubmit: async  (values, { resetForm }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_PATH}/contactUs`, values); 
        console.log(response.data); 
        resetForm();
      } catch (error) {
        console.error('Error:', error);
      }
    
    },
    validate: (values) => {
      const errors = {};
      if (!values.contactName) errors.contactName = "Contact Name is required";
      if (!values.Organisation) errors.Organisation = "Organisation Name is required";
      if (!values.contactNumber) {
        errors.contactNumber = "Contact Number is required";
      } else if (!/^\d{10}$/.test(values.contactNumber)) {
        errors.contactNumber = "Contact Number must be 10 digits";
      }
      if (!values.Comments) errors.Comments = "Comments are required";
    
      if (!values.corporateEmail) errors.corporateEmail = "Email is required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.corporateEmail))
        errors.corporateEmail = "Invalid email address";
    
      return errors;
    },
    
  });

  return (
    <>
      <Header />
      <div className="border1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
        <div className=" container">
          <form onSubmit={formik.handleSubmit}>
            <section className="gradient-custom">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <h2 className="text-center mb-4"><span>Contact </span>Us</h2>
                  <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div className="card bg-white text-black" style={{ borderRadius: "1rem", boxShadow: "#0d6efd73 0px 2px 4px" }}>
                      <div className="card-body p-5">
                        <div className="mb-md-5">
                          <div className="mb-3">
                            <label htmlFor="contactName" className="form-label">Contact Name <span style={{color:"#ff0000b8"}}>*</span></label>
                            <input type="text" className="form-control" id="contactName" name="contactName" onChange={formik.handleChange} value={formik.values.contactName}  />
                            {formik.touched.contactName && formik.errors.contactName ? <div style={{ color: "red", fontSize: "14px" }}>{formik.errors.contactName}</div> : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="organisation" className="form-label">Organisation <span style={{color:"#ff0000b8"}}>*</span></label>
                            <input type="text" id="organisation" name="Organisation" className="form-control" onChange={formik.handleChange} value={formik.values.Organisation}  />
                            {formik.touched.Organisation && formik.errors.Organisation ? <div style={{ color: "red", fontSize: "14px" }}>{formik.errors.Organisation}</div> : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="corporateEmail" className="form-label">Corporate Email <span style={{color:"#ff0000b8"}}>*</span></label>
                            <input type="text" id="corporateEmail" name="corporateEmail" className="form-control" onChange={formik.handleChange} value={formik.values.corporateEmail}  />
                            {formik.touched.corporateEmail && formik.errors.corporateEmail ? <div style={{ color: "red", fontSize: "14px" }}>{formik.errors.corporateEmail}</div> : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="contactNumber" className="form-label">Contact Number <span style={{color:"#ff0000b8"}}>*</span></label>
                            <input type="tel" id="contactNumber" name="contactNumber" className="form-control" onChange={formik.handleChange} value={formik.values.contactNumber}  />
                            {formik.touched.contactNumber && formik.errors.contactNumber ? <div style={{ color: "red", fontSize: "14px" }}>{formik.errors.contactNumber}</div> : null}
                          </div>
                          <div className="mb-3">
                            <label htmlFor="Comments" className="form-label">Comments <span style={{color:"#ff0000b8"}}>*</span></label>
                            <textarea type="text" id="Comments" name="Comments" className="form-control" onChange={formik.handleChange} value={formik.values.Comments}  ></textarea>
                            {formik.touched.Comments && formik.errors.Comments ? <div style={{ color: "red", fontSize: "14px" }}>{formik.errors.Comments}</div> : null}
                          </div>
                          <div className="text-center">
                            <button className="btn btn-primary d-block w-100" type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card bg-white text-black mt-5" style={{ borderRadius: "1rem", boxShadow: "#0d6efd73 0px 2px 4px" }}><div class="card-body"><div class="d-flex justify-content-center align-items-center p-4"><div class="text-primary contact-icon"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-geo" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"></path></svg></div><div><h2 class="mb-3">Head Office</h2><ul class="ContactLink font text-bold list-unstyled m-0"><li class="address-item">Ground Floor, 470 St Kilda Road</li><li class="address-item">Melbourne, Vic</li><li class="address-item">Australia 3004</li></ul></div></div></div></div>
                  </div>
                  
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
      <div className="border1">
      <div data-aos="fade-right" id="footer">        <Footer /> </div>
      </div>
    </>
  );
};

export default ContactUs;