import { CardContent, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import MDInput from "./MDInput";
import questionsData from "../components/Questionare/All.json";
import Autocomplete from "@mui/material/Autocomplete";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import MDButton from "./MDButton";

const Hobbies = ({ closeEditMode, user, setUser }) => {
  const [hobbies, setHobbies] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentCategory = 2;

  useEffect(() => {
    if (user.hobbies) {
      const userHobbies = user.hobbies || [];
      setHobbies(userHobbies.slice(0, 10));
    }
  }, [user]);

  useEffect(() => {
    setIsSubmitDisabled(hobbies.length !== 10);
  }, [hobbies]);

  const handleOptionSelect = (event, value) => {
    if (event.type === "keydown" && event.key === "Backspace") {
      if (value.length === hobbies.length) {
        setHobbies([]);
      }
    } else {
      const remainingOptions = value.slice(0, 10);
      setHobbies(remainingOptions);
    }
  };

  const handleRemoveOption = (index) => {
    const updatedHobbies = hobbies.filter((_, i) => i !== index);
    setHobbies(updatedHobbies);
  };

  const handleUpdateHobbies = () => {
    const value = hobbies.filter((item) => item !== null && item !== "");

    axios
      .post(`${process.env.REACT_APP_API_PATH}/saveHobbies`, {
        value: value,
        token: localStorage.getItem("token"),
      })
      .then((res) => {
        closeEditMode();
        const updatedUser = { ...user };
        updatedUser.hobbies = value;
        setUser(updatedUser);
        Swal.fire("Updated!", "", "success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <CardContent style={{ padding: '0px' }}>
        <Autocomplete
          multiple
          limitTags={10}
          options={questionsData[currentCategory].questions}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Select your interests/hobbies"
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                style: { cursor: "default", userSelect: "none" },
              }}
            />
          )}
          onChange={handleOptionSelect}
          value={hobbies}
          disableClearable
          style={{
            width: isSmallScreen ? "91%" : "100%",
            marginBottom: "20px",
          }}
          renderTags={() => null} // Disable rendering of tags
          renderOption={(props, option, { selected }) => (
            <li
              {...props}
              style={{
                backgroundColor: selected ? "#e0e0e0" : "transparent",
              }}
            >
              {option}
            </li>
          )}
        />
        <ol style={{ marginLeft: "22px" }}>
          {hobbies.slice(0, 10).map((hobby, index) => (
            <li key={index}>
              {hobby}
              <IconButton size="small">
                <CancelIcon onClick={() => handleRemoveOption(index)} />
              </IconButton>
            </li>
          ))}
        </ol>
      </CardContent>
      <MDButton
        color="info"
        sx={{backgroundColor:"#0d6efd"}}
        disabled={isSubmitDisabled}
        onClick={handleUpdateHobbies}
      >
        Update
      </MDButton>
      <MDButton variant="text" color="info" onClick={closeEditMode}>
        Cancel
      </MDButton>
    </div>
  );
};

export default Hobbies;
