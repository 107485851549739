import { Grid, Container, Box, Card, CircularProgress, Avatar } from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Nav from "./Navbar";

function Connections() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [connections, setConnections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const userProfile = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
        .then((res) => {
          if (res.data.status === 200) {
            setUser(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const handleConnections = () => {
    const token = localStorage.getItem("token");

    return axios
      .post(`${process.env.REACT_APP_API_PATH}/connections`, { token })
      .then((res) => {
        if (res.data.status === 200) {
          setConnections(res.data.data.connections);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    userProfile();
    handleConnections().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (user && !user.iscomplete) {
      navigate("/questionere");
    }
  }, [user?.iscomplete]);

  return (
    <>
      <Nav data={user} />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        ></Box>
        <MDBox p={2} mt={5}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={4}>
              
              {connections.length === 0 ? (
                <Grid item xs={12}>
                  <Box>
                    <Card>
                      <MDBox
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          mt: 5,
                        }}
                      >
                        <MDTypography variant="h5" p={5}>
                          No connections found.
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </Box>
                </Grid>
              ) : (
                connections.map((connection) => (
                  <Grid item xs={12} md={6} xl={4} key={connection._id}>
                    <Box>
                      <Link
                        to={`/connections/publicprofile/${connection._id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Card>
                          <MDBox
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              mt: 5,
                              height: "350px",
                            }}
                          >
                            {connection?.matchedUser?.image ? (
                              <>
                                <img
                                  src={connection?.matchedUser?.image}
                                  alt="..."
                                  style={{ height: 120, width: 120, borderRadius: "80px" }}
                                />
                              </>
                            ) : (
                              <Avatar
                                htmlFor="image-upload"
                                label="Image Upload"
                                sx={{
                                  height: 150,
                                  mb: 2,
                                  width: 150,
                                  cursor: "pointer",
                                }}
                                style={{ height: 120, width: 120 }}
                              />
                            )}

                            <MDTypography
                              variant="h5"
                              alignItems="center"
                              mt={2}
                              style={{ cursor: "pointer" }}
                            >
                              {connection.matchedUser?.firstname || ""}
                            </MDTypography>

                            <MDBox mt={0}>
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <MDTypography variant="button" color="text" fontWeight="medium">
                                  {connection.matchedUser?.userRole || ""}
                                </MDTypography>{" "}
                              </MDTypography>
                            </MDBox>
                            <MDBox mt={0}>
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <MDTypography variant="button" color="text" fontWeight="medium">
                                  Connection Date: {formatDate(connection?.connectionDate) || ""}
                                </MDTypography>{" "}
                              </MDTypography>
                            </MDBox>
                            <MDBox mb={2}>
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <MDTypography variant="button" color="text" fontWeight="medium">
                                  Compatibility Score: {Math.round(connection?.compatibleAvg || "")}
                                </MDTypography>{" "}
                              </MDTypography>
                            </MDBox>
                            <MDBox mt={0} mb={2}>
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <MDTypography
                                  variant="button"
                                  color="text"
                                  fontWeight="medium"
                                ></MDTypography>{" "}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </Card>
                      </Link>
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </MDBox>
      </Container>
    </>
  );
}

export default Connections;