import { Icon, Typography } from "@mui/material";
import Connections from "components/UserDashboard/Connection";
import UserProfile from "components/UserDashboard/UserProfile";

const UserRoutes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}> Profile </Typography>,
    key: "profile",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/userprofile",
    component: <UserProfile />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}> Connections </Typography>,
    key: "connections",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/connections",
    component: <Connections />,
  },
];

export default UserRoutes;
