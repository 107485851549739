import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import 'MainComponet/css/custom.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
const PrivacyStatement = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);
  return (
    <>
    <Header/>
    <figure>
        <section className="border1">
          <section className="container">
            <section className="row align-items-center">
              <section className="col-sm-12 bannerContent" data-aos="fade-left"
               data-aos-duration="3000"> 
                <h2>
                  <span>The Connectar</span> Privacy Statement
                </h2>
              </section>
            </section>
          </section>
        </section>
      </figure>

      {/* Privacy Statement Content */}
      <section className="content-widget">
        <section className="container">
          <section className="row align-items-center content-rows" data-aos="fade-down"
               data-aos-duration="3000">
            <section className="col-md-12">
              <h2>Privacy Statement</h2>
              <p className="justify-text font">The Connectar is committed to protecting the privacy and security of your personal information. This Privacy Statement explains how we collect, use, disclose, and protect the personal information we obtain.</p>
              <h2>Information We Collect</h2>
              <p className="justify-text font">We may collect personal information when you interact with our website or services. This information may include:</p>
              <ul className='font'>
                <li>Contact information (such as name, email address, phone number)</li>
                <li>Demographic information (such as age, gender, location)</li>
                <li>Login credentials (such as username, password)</li>
                <li>Payment information (such as credit card details)</li>
              </ul>
              <h2>How We Use Your Information</h2>
              <p className='font'>We may use the personal information we collect for various purposes, including:</p>
              <ul className='font'>
                <li>Providing and personalizing our products and services</li>
                <li>Processing transactions and fulfilling orders</li>
                <li>Communicating with you about your account or inquiries</li>
                <li>Sending promotional offers, newsletters, and marketing communications</li>
                <li>Conducting research and analysis to improve our products and services</li>
                <li>Complying with legal obligations and enforcing our policies</li>
              </ul>
              <h2>Information Sharing</h2>
              <p className="justify-text font">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Statement or as required by law. We may share your information with:</p>
              <ul className='font'>
                <li>Service providers and partners who assist us in operating our website and conducting our business</li>
                <li>Legal authorities or law enforcement agencies in response to lawful requests or legal process</li>
                <li>Other parties with your consent or at your direction</li>
              </ul>
              <h2>Data Security</h2>
              <p className="justify-text font">We implement security measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and although we endeavour to ensure every step is taken to secure your data, we cannot guarantee absolute security.</p>
              <h2>Your Choices</h2>
              <p className="justify-text font">You may choose not to provide certain personal information, but this may limit your ability to use certain features of our website or access certain services.</p>
              <h2>Updates to this Privacy Statement</h2>
              <p className="justify-text font">We may update this Privacy Statement from time to time to reflect changes in our practices or legal requirements. We encourage you to review this Privacy Statement periodically for any updates.</p>
              <h2>Contact Us</h2>
              <p className="justify-text font">If you have any questions, concerns, or requests regarding this Privacy Statement or our practices concerning your personal information, please contact us at [insert contact information].</p>
            </section>
          </section>
        </section>
      </section>

      <Footer/>
    </>
  )
}

export default PrivacyStatement
