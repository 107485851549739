import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import "react-toastify/dist/ReactToastify.css";
import logo from "assets/images/small-logos/image.png";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

const style = {
  "&:hover": {
    background: "#ffac33",
  },
};

function Feedback() {
  const [feedback, setFeedback] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleEmojiSelect = (emoji) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/", { state: id });
      return;
    }

    setSelectedEmoji(emoji);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      comments: feedback,
      emoji: selectedEmoji,
      token: token,
      notes: notes,
      connectionId: id,
    };
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_PATH}/submit-feedback`, formData);

      if (res.data.message) {
        Swal.fire({
          icon: "success",
          title: "Thank you!",
          text: res.data.message,
        }).then(() => {
          setSelectedEmoji(null);
          setFeedback("");
          setNotes("");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unexpected server response. Please try again later.",
        });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          sx={{backgroundColor:"#1e90ff"}}
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox
            className="Logo_design"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            zIndex={3}
            component="img"
            src={logo}
            sx={{ height: "80px", margin: "auto " }}
            alt="Brand"
          />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Your Feedback
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <form>
            <MDTypography variant="h5" textAlign="center" mt={1}>
              How was your meetup?
            </MDTypography>
            <MDBox display="flex" justifyContent="center" mb={2}>
              <Tooltip title="Great" arrow>
                <IconButton
                  onClick={() => handleEmojiSelect("great")}
                  sx={style}
                  color={selectedEmoji === "great" ? "primary" : "default"}
                >
                  <InsertEmoticonIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Good" arrow>
                <IconButton
                  onClick={() => handleEmojiSelect("good")}
                  sx={style}
                  color={selectedEmoji === "good" ? "primary" : "default"}
                >
                  <SentimentSatisfiedAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="OK" arrow>
                <IconButton
                  onClick={() => handleEmojiSelect("ok")}
                  sx={style}
                  color={selectedEmoji === "ok" ? "primary" : "default"}
                >
                  <SentimentNeutralIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bad" arrow>
                <IconButton
                  onClick={() => handleEmojiSelect("bad")}
                  sx={style}
                  color={selectedEmoji === "bad" ? "primary" : "default"}
                >
                  <SentimentVeryDissatisfiedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Not Good" arrow>
                <IconButton
                  onClick={() => handleEmojiSelect("not good")}
                  sx={style}
                  color={selectedEmoji === "not good" ? "primary" : "default"}
                >
                  <SentimentDissatisfiedIcon />
                </IconButton>
              </Tooltip>
            </MDBox>

            {selectedEmoji && (
              <>
                <MDBox pb={3} px={3}>
                  <MDInput
                    label="Comments"
                    variant="outlined"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    multiline
                    rows={3}
                    fullWidth
                    required
                  />
                </MDBox>
                <MDBox pb={1} px={3}>
                  <MDInput
                    label="Notes?"
                    variant="outlined"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    multiline
                    rows={2}
                    fullWidth
                  />
                </MDBox>
                <MDBox textAlign="center">
                  <MDButton
                    type="submit"
                    variant="contained"
                    color="info"
                    fullWidth
                    style={{ marginTop: "15px" }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </>
            )}
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Feedback;
