import React, { useEffect } from "react";
import howItWork from "../assets/images/Video/TheConnectarFinal.mp4";

const Videoplayer = React.forwardRef((_, ref) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.preload = "auto";
      ref.current.load();

      ref.current.play().then(() => {
        console.log("Video is playing automatically");
      }).catch((err) => {
        console.error("Autoplay failed:", err);
      });
    }
  }, [ref]);

  return (
    <video
      ref={ref}
      id="videoPlayer"
      title="Video Title"
      className="videoframe"
      loop
      controls
      allow="autoplay; fullscreen;"
      allowFullScreen
      muted
      autoPlay
      height="400"
    >
      <source src={howItWork} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
});

export default Videoplayer;
